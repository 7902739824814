import { FunctionComponent } from 'react';
import styles from './index.module.css';

const PrivacyNoticeUSA: FunctionComponent = () => {
  return (
    <>
      <embed src="/docs/PRIVACY_US.pdf" className={styles.legalEmbed} />
    </>
  );
};

export default PrivacyNoticeUSA;

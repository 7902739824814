import { FunctionComponent } from 'react';
import styles from './index.module.css';

const AntiSlaveryStatement: FunctionComponent = () => {
  return (
    <>
      <iframe
        title="AntiSlaveryStatement"
        src="https://www.exposure.net/london/anti-slavery-statement/"
        className={styles.iframe}
      />
    </>
  );
};

export default AntiSlaveryStatement;

import { FunctionComponent } from 'react';
import styles from './index.module.css';

const PrivacyNoticeUK: FunctionComponent = () => {
  return (
    <>
      <iframe
        title="PrivacyNotice UK"
        src="https://www.exposure.net/london/privacy-notice/"
        className={styles.iframe}
      />
    </>
  );
};

export default PrivacyNoticeUK;

import { FunctionComponent } from 'react';
import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import Email from 'assets/img/Email.png';
import Tiktok from 'assets/img/Tiktok.png';
import Instagram from 'assets/img/Instagram.png';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useAppSelector } from '../../../redux/hooks';

interface IFooterProps {}
const Footer: FunctionComponent<IFooterProps> = (props) => {
  const footer = useAppSelector((state) => state.layout.footer);
  const mainMenu = useAppSelector((state) => state.layout.mainMenu);

  const { column1, column2, column3, socialLinks = [] } = { ...footer };
  const { links = [] } = { ...mainMenu };

  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.title}>Community X SEEN</div>

        <div className={styles.footerContent}>
          {/* nav */}
          <div className={styles.footerNav}>
            <div className={styles.subtitle}>Pages</div>
            <ul className={styles.bottomNav}>
              {/* Links */}
              {links.map((link) => (
                <li className={styles.navbarLinkItem} key={link.id}>
                  <NavLink
                    className={({ isActive }) =>
                      clsx(
                        styles.navbarLinkItemLink,
                        isActive && styles.bottomNavisActive
                      )
                    }
                    to={link.url}
                  >
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* addresses*/}
          <div className={styles.footerAddresses}>
            <div className={styles.subtitle}>Addresses</div>
            <div className={styles.footerAddressesColumns}>
              {column1 && (
                <div>
                  <ReactMarkdown
                    className={styles.text}
                    children={column1}
                    components={{
                      h4: ({ node, ...props }) => (
                        <h4 className={styles.textH4} {...props}>
                          {props.children}
                        </h4>
                      ),
                    }}
                  />
                </div>
              )}
              {column2 && (
                <div>
                  <ReactMarkdown
                    className={styles.text}
                    children={column2}
                    components={{
                      h4: ({ node, ...props }) => (
                        <h4 className={styles.textH4} {...props}>
                          {props.children}
                        </h4>
                      ),
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {/* contact */}
          <div className={styles.footerContact}>
            <div className={styles.subtitle}>Contact</div>
            {column3 && (
              <ReactMarkdown
                className={styles.text}
                children={column3}
                linkTarget="_blank"
                components={{
                  h4: ({ node, ...props }) => (
                    <h4 className={styles.textH4} {...props}>
                      {props.children}
                    </h4>
                  ),
                }}
              />
            )}
            {!!socialLinks.length && (
              <div className={styles.socialLinksWrapper}>
                <ul className={styles.socialLinks}>
                  {socialLinks.map((socialLink, socialLinkIndex) => {
                    const { url, icon } = socialLink;
                    const image =
                      icon === 'Instagram'
                        ? Instagram
                        : icon === 'Tiktok'
                        ? Tiktok
                        : icon === 'Mail'
                        ? Email
                        : '';
                    return (
                      <li
                        className={styles.socialLink}
                        key={`social-${socialLinkIndex}`}
                      >
                        <a href={url} target="_blank" rel="noreferrer">
                          <img
                            src={image}
                            alt="social media"
                            className={styles.socialIcon}
                          />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div>
            <span className={styles.copyright}>
              <span className={styles.copyrightYear}>
                © Copyright SEEN Group 2022
              </span>
              <a
                href="/legal/anti-slavery-statement"
                target="_blank"
                rel="noreferrer"
              >
                Anti Slavery Statement
              </a>
              <a href="/legal/privacy-notice" target="_blank" rel="noreferrer">
                Privacy Notice
              </a>
              <a
                href="/legal/sustainability-policy"
                target="_blank"
                rel="noreferrer"
              >
                Sustainability Policy
              </a>
              <a href="/legal/terms-of-use" target="_blank" rel="noreferrer">
                Terms Of Use
              </a>
            </span>
          </div>
          <div>
            <span className={styles.copyright}>
              Registered in England & Wales as Beauty SEEN Ltd 20 Little
              Portland Street, London, W1W 8BS No. 06270260 VAT No. 912298817
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

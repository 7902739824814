import Footer from 'components/shared/Footer';
import MainMenu from 'components/shared/MainMenu';
import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageStartCampaign } from 'types/generated/strapi';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import SeoHead from 'components/shared/SeoHead';
import TitleCenter from 'components/sections/TitleCenter';
import styles from './index.module.css';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';
import { useNavigate } from 'react-router-dom';
import { setCalendlyEnviteeId } from 'utils/calendlyEnviteeId';

const StartCampaign: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageStartCampaign>();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageStartCampaign>(
        getStrapiURL('/page-start-campaign')
      );
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  useCalendlyEventListener({
    onEventScheduled: (e: any) => {
      const calendlyInviteeUri = e.data?.payload?.invitee?.uri;
      if (!calendlyInviteeUri) {
        setError('Something goes wrong, please contact us');
        return;
      }
      const calendlyInviteeId = calendlyInviteeUri.split('/').pop();
      setCalendlyEnviteeId(calendlyInviteeId);
      navigate('/start-campaign/info');
    },
  });

  const {
    iframeHeight = 0,
    seo,
    titleCenter,
    pageBackgroundColor,
  } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          <div className={styles.titleCenterWrapper}>
            {titleCenter && <TitleCenter data={titleCenter} />}
          </div>
          {error && <div className={styles.error}>{error}</div>}
          <InlineWidget
            styles={{
              height: iframeHeight + 'px',
              backgroundColor: pageBackgroundColor,
            }}
            pageSettings={{
              backgroundColor: 'ffffff',
              primaryColor: '5f1e19',
              textColor: '5f1e19',
              hideEventTypeDetails: true,
              hideGdprBanner: true,
            }}
            url="https://calendly.com/communityxseen/30min"
          />
        </>
      )}

      <Footer />
    </>
  );
};

export default StartCampaign;

import { useCallback, useState } from 'react';
import styles from './index.module.css';
import { IForm } from 'types/generated/strapi';
import { Form as FinalForm } from 'react-final-form';
import Fields from './Fields';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from 'utils/urls';
import { getCalendlyEnviteeId } from 'utils/calendlyEnviteeId';
import ReactMarkdown from 'react-markdown';

const Form = (props: { form: IForm }) => {
  const { form } = props;
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onSubmit = useCallback(
    async (values) => {
      const data = Object.keys(values).map((label) => {
        const exist = form.fields.find((field) => field.label === label);

        if (!exist) return null; // In theory never

        return {
          ...exist,
          value: Array.isArray(values[label])
            ? values[label].join('\n')
            : values[label],
        };
      });

      const resp = await strapi.post(getStrapiURL('/campaign-briefs'), {
        fields: data,
        calendlyInviteeId: getCalendlyEnviteeId(),
      });

      if (!resp.ok) {
        setError('Something goes wrong, please contact us');
      }

      setSuccessMessage(form.successMessage || '');
    },
    [form]
  );

  return (
    <div className={styles.form}>
      <div className={styles.formInner}>
        <FinalForm
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            submitSucceeded,
            submitFailed,
          }) => (
            <form onSubmit={handleSubmit}>
              {form.fields.map((field, index) => {
                const { id, __component } = field;
                const Field = Fields[__component];
                return Field ? (
                  <Field
                    key={`${id} ${index}`}
                    field={field}
                    classNames={{
                      group: styles.group,
                      input: styles.input,
                      label: styles.label,
                      choiceContainerCheckbox: styles.choiceContainerCheckbox,
                      choiceContainerRadio: styles.choiceContainerRadio,
                      choiceLabel: styles.choiceLabel,
                      error: styles.error,
                    }}
                  />
                ) : null;
              })}
              {submitFailed && (
                <p className={styles.error}>
                  Please complete all the required fields
                </p>
              )}
              {error && <p className={styles.error}>{error}</p>}
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.button}
                  type="submit"
                  disabled={submitting || submitSucceeded}
                >
                  {submitSucceeded ? 'Submitted Successfully' : 'Submit'}
                </button>
              </div>
              <ReactMarkdown
                children={successMessage}
                className={styles.successMessage}
              />
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default Form;

import {
  IOneChoice,
  IMultipleChoice,
  IUploadMultipleFiles,
  IText,
  IForm,
} from 'types/generated/strapi';
import { Field, FormSpy } from 'react-final-form';
import { FunctionComponent } from 'react';

const splitByNewLine = (text: string) =>
  text.split(/\r?\n/).map((c) => c.trim());

const required = (value: any) => (value ? undefined : 'Required');
const requiredArray = (value: any) =>
  value && value.length ? undefined : 'Required';

interface IClassNames {
  group?: string;
  label?: string;
  description?: string;
  input?: string;
  radio?: string;
  checkbox?: string;
  error?: string;
  choiceLabel?: string;
  choiceContainerRadio?: string;
  choiceContainerCheckbox?: string;
}

interface ITextProps {
  field: IText;
  classNames?: IClassNames;
}

const TextField = (props: ITextProps) => {
  const { field, classNames = {} } = props;
  const { label } = field;

  return (
    <Field
      validate={label.endsWith('*') ? required : undefined}
      type="text"
      name={label}
    >
      {(props) => {
        const { meta, input } = props;
        return (
          <div className={classNames.group}>
            <label>
              <div className={classNames.label}>{input.name}</div>
              <div className={classNames.description}>{field.description}</div>
              <input className={classNames.input} {...input} />
            </label>
            {meta.touched && meta.error && (
              <span className={classNames.error}>{meta.error}</span>
            )}
          </div>
        );
      }}
    </Field>
  );
};

const ChoiceField = (
  props: IMultipleChoiceProps | IOneChoiceProps,
  type: 'checkbox' | 'radio'
) => {
  const { field, classNames = {} } = props;
  const { label, options = '' } = field;
  const optionsSplittedByNewLine = splitByNewLine(options);

  return (
    <div className={classNames.group}>
      <div className={classNames.label}>{label}</div>
      {optionsSplittedByNewLine.map((option, index) => (
        <Field
          name={label}
          type={type}
          value={option}
          key={`${option} ${index}`}
          validate={label.endsWith('*') ? requiredArray : undefined}
        >
          {(props) => {
            const { input } = props;
            const className =
              type === 'checkbox'
                ? classNames.choiceContainerCheckbox
                : classNames.choiceContainerRadio;

            const classNameInput =
              type === 'checkbox' ? classNames.checkbox : classNames.radio;

            return (
              <div>
                <label className={className}>
                  <input className={classNameInput} {...input} />
                  <span className={classNames.choiceLabel}>{option}</span>
                </label>
              </div>
            );
          }}
        </Field>
      ))}
      <FormSpy subscription={{ errors: true, touched: true }}>
        {(props) => {
          const touched = props.touched?.[label];
          const error = props.errors?.[label];

          return error && touched ? (
            <span className={classNames.error}>{error}</span>
          ) : null;
        }}
      </FormSpy>
    </div>
  );
};

interface IMultipleChoiceProps {
  field: IMultipleChoice;
  classNames?: IClassNames;
}

interface IOneChoiceProps {
  field: IOneChoice;
  classNames?: IClassNames;
}

interface IUploadMultipleFilesProps {
  field: IUploadMultipleFiles;
  classNames?: IClassNames;
}

const UploadMultipleFilesField = (props: IUploadMultipleFilesProps) => {
  const { field, classNames = {} } = props;
  const { label } = field;

  return (
    <Field type="file" name={label}>
      {(props) => {
        const { meta, input } = props;
        return (
          <div className={classNames.group}>
            <label>
              <div className={classNames.label}>{input.name}</div>
              <input className={classNames.input} {...input} />
            </label>
            {meta.touched && meta.error && (
              <span className={classNames.error}>{meta.error}</span>
            )}
          </div>
        );
      }}
    </Field>
  );
};

type fieldName = IForm['fields'][number]['__component'];

const Fields: Record<fieldName, FunctionComponent<any>> = {
  'forms.one-choice': (props) => ChoiceField(props, 'radio'),
  'forms.multiple-choice': (props) => ChoiceField(props, 'checkbox'),
  'forms.text-form-field': TextField,
  'forms.upload-multiple-files': UploadMultipleFilesField,
};

export default Fields;

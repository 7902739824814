import Footer from 'components/shared/Footer';
import MainMenu from 'components/shared/MainMenu';
import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageStartCampaignInfo } from 'types/generated/strapi';
import SeoHead from 'components/shared/SeoHead';
import Form from 'components/shared/Form';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';

const StartCampaignInfo: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageStartCampaignInfo>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageStartCampaignInfo>(
        getStrapiURL('/page-start-campaign-info')
      );
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { seo, form } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {form && <Form form={form} />}
        </>
      )}

      <Footer />
    </>
  );
};

export default StartCampaignInfo;

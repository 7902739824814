import { FunctionComponent } from 'react';
import styles from './index.module.css';

const SustainabilityPolicyPage: FunctionComponent = () => {
  return (
    <>
      <embed
        src="/docs/sustainability-policy.pdf"
        className={styles.legalEmbed}
      />
    </>
  );
};

export default SustainabilityPolicyPage;

import { FunctionComponent } from 'react';
import styles from './index.module.css';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';

const PrivacyNotice: FunctionComponent = () => {
  return (
    <>
      <MainMenu />
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.buttons}>
            <a
              className={styles.button}
              href="/legal/privacy-notice-uk"
              target="_blank"
            >
              Click here for UK Privacy Notice
            </a>
            <a
              className={styles.button}
              href="/legal/privacy-notice-us"
              target="_blank"
            >
              Click here for USA Privacy Notice
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyNotice;

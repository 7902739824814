import { FunctionComponent } from 'react';
import styles from './index.module.css';

const TermsOfUsePageUK: FunctionComponent = () => {
  return (
    <>
      <embed
        src="/docs/COMMUNITY_X_SEEN_Terms_of_Use.pdf"
        className={styles.legalEmbed}
      />
    </>
  );
};

export default TermsOfUsePageUK;
